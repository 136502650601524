import React, { FC } from 'react';

type Props = {
  marginHorizontal?: string;
  marginVertical?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
};

const Divider: FC<Props> = (props) => {
  return (
    <div
      style={{
        borderBottomWidth: '1px',
        borderBottomColor: '#F0F0F0',
        borderBottomStyle: 'solid',
        marginLeft: props.marginLeft || props.marginHorizontal,
        marginRight: props.marginRight || props.marginHorizontal,
        marginTop: props.marginTop || props.marginVertical,
        marginBottom: props.marginBottom || props.marginVertical,
      }}
    />
  );
};

export { Divider };
