import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import category from '@public/category.json';
import { CustomText, NoBorderButton } from '@components/ui';
import { GET_CATEGORY_FILTER } from '@api';
import { FlexRowAlignCenter } from '@components/wrapper';
import { MAIN_CATEGORY, MAIN_CATEGORY_LIST, MAIN_CATEGORY_NAME } from 'src/constants';
import { colors } from '@public/theme';
import { DownArrowIcon, UpArrowIcon } from 'src/assets/icons';
import BrandHomeIcon from 'src/assets/icons/BrandHomeIcon';
import { props, categoryItemsProps } from './model';
import * as Styled from './styled';
import { CategorySkeleton } from './CategorySkeleton';

export const Category = ({ type, categoryCode, onClickCategory, top = 0, brandCode }: props) => {
  // 번역도구
  const { t, i18n } = useTranslation('common');
  // 현재 탐색 중인 카테고리 코드. 하위 카테고리 표시 여뷰를 결정
  const [activeCode, setActiveCode] = useState(categoryCode);
  // 현재 활성화된 대분류
  const [activeMainCategory, setActiveMainCategory] = useState(type || MAIN_CATEGORY.CARTOON);

  // 카테고리 목록을 조회하기 위해 필요한 parameter
  const categoryVariables: categoryItemsProps = {
    lang: i18n.language,
  };

  // 브랜드 코드가 있을 경우에만 variables에 추가
  if (brandCode) categoryVariables.brandCode = brandCode;

  if (type) categoryVariables.type = type;

  // 카테고리 리스트 조회
  const { data, loading } = useQuery(GET_CATEGORY_FILTER, {
    variables: categoryVariables,
  });

  // 카테고리 리스트
  let categoryItems = (!loading && data && data?.syncCategory) || [];

  useEffect(() => {
    if (categoryItems && !type) {
      const cartoonItems = categoryItems.filter((x) => x.type === MAIN_CATEGORY.CARTOON);
      if (cartoonItems.length === 0) setActiveMainCategory(MAIN_CATEGORY.REALISTIC);
    }
  }, [categoryItems]);

  const isHideTab = useMemo(
    () =>
      MAIN_CATEGORY_LIST.filter((x) => {
        return categoryItems.filter((y) => y.type === x).length === 0;
      }).length > 0,
    [categoryItems],
  );

  // 브랜드 페이지에서는 기획전 모음 카테고리를 노출하지 않음.
  if (brandCode !== undefined) {
    categoryItems = categoryItems.filter((it) => {
      return !it.code.startsWith('276002');
    });

    // 기획전 모음 카테고리를 제거한 이후에, 그 상위인 '에이콘 추천' 하위 카테고리가 없을 경우 표시하지 않음.
    if (categoryItems.filter((it) => it.code.startsWith('276')).length === 1) categoryItems = categoryItems.filter((it) => !it.code.startsWith('276'));
  }

  // 현재 선택된 실사렌더, 카툰렌더에 맞게 분류
  categoryItems = categoryItems.filter((x) => x.type === activeMainCategory);

  const renderCategoryItems = useMemo(() => {
    if (!categoryItems || !categoryItems.length) return null;
    return category.rootCategory.map((rootCategory, idx) => {
      const categoryGroupObj = categoryItems.find((x) => x.code === rootCategory);
      const mainItems = categoryItems.filter(
        (x) => x.code.substring(0, 3) === rootCategory && x.code.length === 6 && x.isVisible,
      );
      if (categoryGroupObj) {
        return (
          <Styled.CategoryMainItems key={idx}>
            <CustomText style={{ padding: '0px 0px 16px 0px' }} weight={700} size={14}>
              {categoryGroupObj.name}
            </CustomText>
            {
              mainItems.map((mainItem, idx) => {
                // 현재 페이지의 카테고리 여부
                const isActive = activeCode && mainItem.code === activeCode.substring(0, 6);
                // 3차 카테고리에 해당하는 현재 카테고리인 항목 객체 배열
                const subItems = categoryItems.filter(
                  (x) => x.code.substring(0, 6) === mainItem.code && x.code.length === 9,
                );

                return (
                  <Styled.CategoryMainItem key={idx} isActive={isActive} heightSize={subItems.length * 31 + 22}>
                    {/** isLink를 사용하는 카테고리는 2개 뿐이라 값을 넘기지 않는 경우 하드코딩된 카테고리로 처리 */}
                    <Styled.CategoryMainTitle
                      isLink={mainItem.isLink || [276002, 282002].includes(mainItem.code)}
                      onClick={() => {
                        if (!mainItem.isLink) handleArrowIconClick(mainItem.code);
                        else setCategory(mainItem.code);
                      }}
                    >
                      <span>
                        <Styled.CategoryMainTitleText
                          weight={mainItem.code === categoryCode ? 500 : 400}
                          color={mainItem.code === categoryCode ? '#F300BA' : '#333'}
                        >
                          {mainItem.name.replace(/#(.*?)#/g, '')}
                        </Styled.CategoryMainTitleText>
                      </span>
                      <NoBorderButton
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          handleArrowIconClick(mainItem.code);
                        }}
                      >
                        {isActive ? <UpArrowIcon size={14} /> : <DownArrowIcon size={14} />}
                      </NoBorderButton>
                    </Styled.CategoryMainTitle>
                    <Styled.CategorySubItems>
                      {subItems.map((subItem, i) => {
                        const isSubActive = categoryCode === subItem.code;
                        return (
                          <Styled.CategorySubItem key={i}>
                            <span onClick={() => {
                              setCategory(subItem.code);
                            }}>
                              <Styled.CategorySubItemText
                                weight={isSubActive ? 500 : 400}
                                color={isSubActive ? '#F300BA' : '#333'}
                              >
                                {subItem.name.replace(/#(.*?)#/g, '')}
                              </Styled.CategorySubItemText>
                            </span>
                          </Styled.CategorySubItem>
                        );
                      })
                      }
                    </Styled.CategorySubItems>
                  </Styled.CategoryMainItem>
                );
              })}
          </Styled.CategoryMainItems>
        );
      }
      return null;
    }).filter(item => Boolean(item));
  }, [categoryItems]);

  const handleArrowIconClick = (code) => {
    setActiveCode(code === activeCode ? null : code);
  };

  const setCategory = (code) => {
    setActiveCode(code);
    onClickCategory(code);
  };

  return (
    <Styled.SideBarContainer lang={i18n.language} top={top}>
      {brandCode ? (
        <>
          <Styled.BrandHome onClick={() => onClickCategory('')}>
            <FlexRowAlignCenter>
              <BrandHomeIcon size={28} />
              <CustomText weight={700} size={16} marginLeft={4}>
                {t('brand.home')}
              </CustomText>
            </FlexRowAlignCenter>
          </Styled.BrandHome>
          {!isHideTab ? (
            <div className={'flex'}>
              {MAIN_CATEGORY_LIST.map((code, i) => (
                <Styled.MainCategoryTab
                  key={i}
                  isChecked={activeMainCategory === code}
                  onClick={() => {
                    setActiveMainCategory(code);
                  }}
                >
                  <Styled.MainCategoryText
                    color={activeMainCategory === code ? colors.black.c1 : colors.gray.c11}
                    weight={500}
                  >
                    {t(MAIN_CATEGORY_NAME[code])}
                  </Styled.MainCategoryText>
                </Styled.MainCategoryTab>
              ))}
            </div>
          ) : null}
        </>
      ) : null}
      <Styled.CategoryContainer>
        {categoryItems ? (
          renderCategoryItems
        ) : (
          <CategorySkeleton short={brandCode !== undefined} />
        )}
      </Styled.CategoryContainer>
    </Styled.SideBarContainer>
  );
};
