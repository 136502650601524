import React from 'react';
import { useTheme } from 'styled-components';
import { CustomLink, CustomText } from '@components/ui';
import { FlexRowAlignCenter } from '@components/wrapper';
import { Badge, CountBadge, GNBTabContainer, SubGNBTabContainer, TextWrapperForBadge } from './styled';

const classNameToName = {
  gnb__event: 'event',
  gnb__best: 'best',
  gnb__new: 'new',
  gnb__hotoffer: 'comet-deal',
  gnb__cosmic: 'cosmic-deal',
  gnb__photoreviews: 'photoreviews',
  gnb__abler: 'abler',
};

export const SubGNBTab = ({
  href,
  displayName,
  className = null, // [TODO] : 지금은 사실상 ga 이벤트 태깅용으로만 사용중. 스타일을 주기 위한 용도로 변경되면 prop이 분리되어야 할 듯
  isBadge = false,
  iconComponent = null,
  iconMargin = 0,
}) => {
  const content = (
    <FlexRowAlignCenter>
      {iconComponent}
      <TextWrapperForBadge>
        <CustomText className={className} size={14} medium marginLeft={iconMargin} color={'black'}>
          {isBadge && <Badge />}
          {displayName}
        </CustomText>
      </TextWrapperForBadge>
    </FlexRowAlignCenter>
  );

  if (href) {
    return (
      <SubGNBTabContainer
        isBadge={isBadge}
        {...(className in classNameToName && {
          'data-mixpanel-action': 'click',
          'data-mixpanel-evt': `GNB Click, GNB Click_${classNameToName[className]}`,
          'data-mixpanel-name': classNameToName[className],
        })}
      >
        <CustomLink href={href} className={className}>
          {content}
        </CustomLink>
      </SubGNBTabContainer>
    );
  }
  return <SubGNBTabContainer isBadge={isBadge}>{content}</SubGNBTabContainer>;
};

export const GNBTab = ({
  displayName,
  href,
  marginLeft = 24,
  count = null,
  isCouponBadge = false,
  className = null,
  children = null,
  style = null,
  size = 12,
}) => {
  const theme = useTheme();

  return (
    <GNBTabContainer marginLeft={marginLeft} style={style}>
      <CustomLink href={href} className={className}>
        <FlexRowAlignCenter className={className}>
          <TextWrapperForBadge>
            <CustomText color={theme.colors.activeText} className={className} size={size}>
              {isCouponBadge && <Badge />}
              {displayName}
            </CustomText>
          </TextWrapperForBadge>
          {count !== null && (
            <CountBadge>
              <CustomText medium size={10} color={'#ff00cd'}>
                {count}
              </CustomText>
            </CountBadge>
          )}
        </FlexRowAlignCenter>
      </CustomLink>
      {children}
    </GNBTabContainer>
  );
};
