import React from 'react';
import { useTranslation } from 'next-i18next';
import { Wrapper } from './styled';

function Outdated() {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      {t('unsupported.outdated')}
      <a href="https://www.google.com/chrome/">{t('unsupported.openChrome')}</a>
    </Wrapper>
  );
}

export default Outdated;
