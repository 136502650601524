import styled from 'styled-components';
import { colors } from '@public/theme';

export const BaseButton = styled.button`
  font-size: 1.25rem;
  line-height: 1.5rem;
  border-radius: 0.3rem;
  border: none;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
`;

export const BaseNoBorderButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  background-color: inherit;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

/**
 * 버튼의 테두리 및 텍스트 색상이 colors.pink.c2인 컴포넌트입니다.
 */
export const BorderVividButton = styled(BaseNoBorderButton)<{ width?: number; height?: number }>`
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props) => (props.height ? `height: ${props.height}px;` : '')}
  border-radius: 5px;
  border: 1px solid ${colors.pink.c2};
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.pink.c2};
`;

export const VividButton = styled(BaseNoBorderButton)<{ width?: number; height?: number }>`
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props) => (props.height ? `height: ${props.height}px;` : '')}
  padding: 10px 30px 9px;
  border-radius: 6px;
  position: relative;
  background-color: ${colors.pink.c2};
  border: 1px solid ${colors.pink.c2};
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
`;

export const GrayBorderButton = styled(BaseNoBorderButton)<{ width: number; height: number }>`
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props) => (props.height ? `height: ${props.height}px;` : '')}
  border-radius: 6px;
  border: 1px solid ${colors.gray.c6};
  color: ${colors.pink.c4};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const DarkBlackButton = styled(BaseNoBorderButton)<{ width?: number; height?: number }>`
  ${(props) => (props.width ? `width: ${props.width}px` : '')};
  ${(props) => (props.height ? `height: ${props.height}px` : '')};
  border-radius: 4px;
  border: 1px solid ${colors.gray.c6};
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.black.c2};
  justify-content: center;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
`;

export const DownloadModalButton = styled(BaseNoBorderButton)<{ width?: number; height?: number }>`
  ${(props) => (props.width ? `width: ${props.width}px` : '')};
  ${(props) => (props.height ? `height: ${props.height}px` : '')};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.black.c2};
  justify-content: center;
`;

export const WhiteButton = styled(BaseNoBorderButton)<{ width?: number; height?: number }>`
  ${(props) => (props.width ? `width: ${props.width}px` : '')};
  ${(props) => (props.height ? `height: ${props.height}px` : '')};
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #333333;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const DarkGrayBorderButton = styled(BaseNoBorderButton)`
  padding: 16px 22.5px;
  border: 1px solid #7c7c7c;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
`;

export const CloseButton = styled.div`
  width: 10px;
  height: 10px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7%;
    height: 110%;
    background-color: #333333;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    content: '';
  }

  &:after {
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`;
