import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
import { CustomIcon } from '@components/ui/Icon';
import { SubGNBTab } from '@components/header/tab';
import { CustomLink, CustomText } from '@components/ui';
import GnbHome from 'src/assets/GnbHome';
import { HoverItem } from '@components/wrapper';
import { PATH_PHOTO_REVIEW, PATH_FUNDING } from 'src/routes/paths';
import { Category } from './category';
import * as Styled from './styled';

interface props {
  isSticky: boolean;
  isShowExplore: boolean;
  setIsShowExplore: React.Dispatch<any>;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const SubGnb: React.FC<props> = ({ isSticky, isShowExplore, setIsShowExplore }) => {
  const router = useRouter();
  // 번역 도구
  const { t, i18n } = useTranslation('common');

  // 3D모델 찾기 버튼 클릭 이벤트 처리기 메소드
  const handleExploreButtonClick = () => {
    if (!isMobile) {
      // 모달창 표시
      setIsShowExplore(true);
      // 종료
    }
  };

  // 카테고리 닫기
  const handleCategoryClose = () => {
    setIsShowExplore(false);
  };

  // 카테고리 컴포넌트 제거 메소드
  const handleCategoryRemoveClick = () => {
    // 3D 모델찾기 모달 숨김
    location.hash = '#';
    handleCategoryClose();
  };

  // hash 변경 감지
  useEffect(() => {
    // URL에 #category가 있을 경우 모델 표시
    if (!isShowExplore && location.hash === '#category') {
      // 3D 모델찾기 모달 표시
      setIsShowExplore(true);
    }
  }, [typeof location !== 'undefined' && location.hash]);

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <HoverItem className={'h-full'}>
          <Styled.CategoryButton onClick={handleExploreButtonClick} data-mixpanel-action="click" data-mixpanel-evt="GNB Click, GNB Click_category" data-mixpanel-name="category">
            <SubGNBTab
              // prefetch 위한 코드
              href={!isMobile ? '#category' : `/${i18n.language}/category`}
              iconComponent={<CustomIcon src="/icons/category/hamburger-icon.png" alt="category" title="category" width="14px" height="14px" />}
              iconMargin={8}
              className="gnb__category"
              displayName={t('menu.subgnb.findModel')}
            />
          </Styled.CategoryButton>
        </HoverItem>
        <Category isShow={isShowExplore} isMobile={false} onClick={handleCategoryRemoveClick} onClose={handleCategoryClose} />

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/event'}>
            <SubGNBTab className="gnb__event" displayName={t('menu.subgnb.event')} href="/event" />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/best'}>
            <SubGNBTab className="gnb__best" displayName={t('menu.subgnb.best')} href="/best" />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/new'}>
            <SubGNBTab className="gnb__new" displayName={t('menu.subgnb.new')} href="/new" />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/event/comet-deal'}>
            <SubGNBTab isBadge={false} displayName={t('menu.subgnb.cometDeal')} href="/event/comet-deal" className="gnb__hotoffer" />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === '/event/cosmic-deal'}>
            <SubGNBTab className="gnb__cosmic" displayName={t('menu.subgnb.cosmicDeal')} isBadge={false} href="/event/cosmic-deal" />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.pathname === PATH_PHOTO_REVIEW.root}>
            <SubGNBTab displayName={t('menu.subgnb.photoreview')} className="gnb__photoreviews" href={PATH_PHOTO_REVIEW.root} isBadge={false} />
          </Styled.CommonLi>
        </HoverItem>

        {/** [todo]: 실서버에서 펀딩 QA를 진행하기 위해 임시 주석처리 */}
        <HoverItem className={'h-full'}>
          <Styled.CommonLi isActive={router.asPath.indexOf(PATH_FUNDING.root) > -1}>
            <SubGNBTab isBadge={new Date() <= new Date('2022-12-31T23:59:59+09:00')} displayName={t('menu.subgnb.funding')} className="gnb__funding" href={PATH_FUNDING.root} />
          </Styled.CommonLi>
        </HoverItem>

        <HoverItem className={'h-full'}>
          <CustomLink className={'menu__upload gnb__upload'} href={'/intro/3d-creator'}>
            <div className={'flex items-center'}>
              <GnbHome className={'mr-1'} />
              <CustomText weight={500} color={'#222222'}>
                {t('game.upload')}
              </CustomText>
            </div>
          </CustomLink>
        </HoverItem>
      </Styled.Container>
    </Styled.Wrapper>
  );
};
