import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

export default function RightArrowIcon({ color = '#333', weight = 'normal', className, size, onClick }: IconProps) {
  const strokeWidth = weight === 'thin' ? 1.5 : 2;

  return (
    <Styled.IconWrapper className={className} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 5L15.5 12L8.5 19" stroke={color} strokeWidth={strokeWidth} />
      </svg>
    </Styled.IconWrapper>
  );
}
