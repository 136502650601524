import { IJoinEvent, IProductClickEvent, IShareViewEvent } from './type';
import mixpanel from '.';

export const productClickEventLog = (property: IProductClickEvent) => {
  if (!property.pageName) return;

  mixpanel.event('Product Click', {
    'Page Name': property.pageName,
    'Action Name': property.actionName,
    'Product Name': property.productName,
    'Product No': property.productNo,
    ...(property?.groupTitle ? { 'Group Title': property?.groupTitle } : {}),
    ...(property?.groupSno ? { 'Group Sno': property?.groupSno } : {}),
    ...(property?.keyword ? { Keyword: property?.keyword } : {}),
    Description: `order_${property.order}`,
  });

  return true;
};

export const shareViewEventLog = (property: IShareViewEvent) => {
  if (!property.url) return;
  mixpanel.event('Share View', property);
};

export const productHistoryEventLog = (returnUrl: string) => {
  const url = new URL(window.location.origin + returnUrl);
  const mixpanelProperties: IJoinEvent = {};
  const urlParams = url?.search ? new URLSearchParams(url.search) : null;
  const splitUrl = (url.pathname?.split('/') || []).filter(Boolean);

  ['category', 'brand', 'product'].forEach((item) => {
    if (splitUrl.length === 2 && splitUrl[0] === item) {
      const valueKeyName = item === 'product' ? 'productNo' : 'categoryCode';
      mixpanelProperties.type = item;
      mixpanelProperties[valueKeyName] = splitUrl[1];
    }
  });

  if (splitUrl[0] === 'search' && urlParams?.get('keyword')) {
    mixpanelProperties.type = 'search';
    mixpanelProperties.keyword = urlParams.get('keyword');
  }

  if (Object.keys(mixpanelProperties).length > 0) mixpanel.event('Product History', mixpanelProperties);
};
