import React, { useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { CustomText, NoBorderButton } from '@components/ui';
import { LANGUAGE_CODE } from 'src/constants';
import useSettings from '@hooks/useSettings';
import { CloseIcon } from 'src/assets/icons';
import { Global, SelectWrapper } from './styled';
import { Button, Container, Select, Wrapper } from './RecommendLanguageStyled';

type Props = {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function RecommendLanguage({ isShow, setIsShow }: Props) {
  const { t, i18n } = useTranslation('common');
  const { themeMode } = useSettings();
  const router = useRouter();

  // 브라우저 언어
  const [browserLang, setBrowserLang] = useState('');
  // 현재 언어
  const [lang, setLang] = useState('');
  // 쿠키
  const [cookie, setCookie] = useCookies();

  // 언어 표시 정렬 정의
  const languageSort = {
    ko: [LANGUAGE_CODE.KO, LANGUAGE_CODE.EN, LANGUAGE_CODE.CN, LANGUAGE_CODE.JP],
    en: [LANGUAGE_CODE.EN, LANGUAGE_CODE.KO, LANGUAGE_CODE.CN, LANGUAGE_CODE.JP],
    zh: [LANGUAGE_CODE.CN, LANGUAGE_CODE.KO, LANGUAGE_CODE.EN, LANGUAGE_CODE.JP],
    ja: [LANGUAGE_CODE.JP, LANGUAGE_CODE.KO, LANGUAGE_CODE.EN, LANGUAGE_CODE.CN],
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // 브라우저 언어
      let browserLanguage = window.navigator.language.substring(0, 2);

      // 브라우저 언어가 지원하는 언어에 소속되어있지 않은경우, en으로 default
      browserLanguage =
        [LANGUAGE_CODE.KO, LANGUAGE_CODE.EN, LANGUAGE_CODE.CN, LANGUAGE_CODE.JP].filter((x) => x === browserLanguage)
          .length === 0
          ? LANGUAGE_CODE.EN
          : browserLanguage;

      setLang(browserLanguage);
      setBrowserLang(browserLanguage);
    }
  }, []);

  useEffect(() => {
    // 브라우저 언어가 존재하고,
    // i18n 언어와 브라우저 언어가 다르고, 종료 여부가 존재하지 않을경우
    if (browserLang && i18n.language !== browserLang && cookie.is_display_recommand_language === undefined) {
      // 추천 언어를 표시해줌
      setIsShow(true);
      // 종료
      return;
    }
    // 추천 언어를 숨김
    setIsShow(false);
  }, [browserLang]);

  // 언어 변경 이벤트
  const changeLanguage = (e) => {
    // 변경한 언어로 상태 변경
    setLang(e.target.value);
  };

  // 선택 버튼 클릭 이벤트 처리기 메소드
  const handleSelectButtonClick = () => {
    router.replace(router.asPath, router.asPath, { locale: lang });
  };

  // 종료 버튼 클릭 이벤트 처리기 메소드
  const handleCloseButtonClick = () => {
    const date = new Date();
    date.setTime(date.getTime() + 7 * 86400000);
    // 7일동안 숨김
    setCookie('is_display_recommand_language', 'N', { path: '/', expires: date });
    // 추천 언어 바 숨김
    setIsShow(false);
  };

  return isShow ? (
    <>
      <Wrapper>
        <Container>
          <CustomText color={'#000'} size={14}>
            {(() => {
              // 브라우저 언어가 ko 일 경우
              if (browserLang === LANGUAGE_CODE.KO) return '이 페이지의 언어를 변경할 수 있습니다.';
              if (browserLang === LANGUAGE_CODE.CN) return '请选择您所需的语言。';
              if (browserLang === LANGUAGE_CODE.JP) return 'ページを好きな言語に翻訳してください。';

              // ko, zh, ja 가 아닐경우에 영문으로 표시해줌
              return 'Translate the page to your preferred language.';
            })()}
          </CustomText>
          <Global>
            <Image src="/icons/footer/global-icon.svg" alt="global" title="global" width="18" height="18" />
            <SelectWrapper>
              <Select value={lang} onChange={changeLanguage}>
                {languageSort[browserLang]
                  ?.filter(
                    (x) =>
                      themeMode === 'toon' ||
                      (themeMode === 'game' && x !== LANGUAGE_CODE.CN && x !== LANGUAGE_CODE.JP),
                  )
                  .map((x) => (
                    <option key={x} value={x}>
                      {t(x)}
                    </option>
                  ))}
              </Select>
            </SelectWrapper>
            <Button onClick={handleSelectButtonClick}>
              <CustomText color={'#fff'} size={12}>
                {(() => {
                  // 브라우저 언어가 ko 일 경우
                  if (browserLang === LANGUAGE_CODE.KO) return '선택';
                  if (browserLang === LANGUAGE_CODE.CN) return '继续';
                  if (browserLang === LANGUAGE_CODE.JP) return '選択';

                  // ko, zh, ja 가 아닐경우에 영문으로 표시해줌
                  return 'GO';
                })()}
              </CustomText>
            </Button>
          </Global>
        </Container>

        {/* 종료 버튼 */}
        <NoBorderButton onClick={handleCloseButtonClick}>
          <CloseIcon size={16} />
        </NoBorderButton>
      </Wrapper>
    </>
  ) : (
    <></>
  );
}
