import React from 'react';
import { IconProps } from './type';
import * as Styled from './styled';

type Props = IconProps & {
  customStroke?: number;
};

export default function CloseIcon({ className, size, weight = 'thin', customStroke, color = '#333' }: Props) {
  const strokeWidth = customStroke ? customStroke : weight === 'thin' ? 1.5 : 2;
  return (
    <Styled.IconWrapper className={className}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 5L5 19" stroke={color} strokeWidth={strokeWidth} />
        <path d="M19 19L5 5" stroke={color} strokeWidth={strokeWidth} />
      </svg>
    </Styled.IconWrapper>
  );
}
