import styled, { keyframes } from 'styled-components';

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

export const TitleWrapper = styled.div`
  border-bottom: 1px #eee solid;
  margin-bottom: 28px;
  padding: 0px 5px 0px 0px;
`;

export const TitleTop = styled.div`
  padding-bottom: 12px;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleView = styled.span`
  cursor: pointer;
  position: relative;
  top: 4px;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }
`;

export const TitleSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleSkeletonText = styled.div`
  width: 250px;
  height: 35px;
  background-image: linear-gradient(lightgray 178px, transparent 0);
  background-repeat: repeat-y;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
`;

export const TitleSkeletonView = styled.div`
  width: 50px;
  height: 20px;
  background-image: linear-gradient(lightgray 178px, transparent 0);
  background-repeat: repeat-y;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
`;

export const TitleTextContainer = styled.div`
  display: flex;
`;

export const TitleSubText = styled.div<{ color: string }>`
  margin-left: 28px;
  padding: 9px 16px 7px 16px;
  border-radius: 20px;
  ${(props) => `background-color: ${props.color};`}
`;
