import React from 'react';
import { useTheme } from 'styled-components';

export default function GnbScrap() {
  const theme = useTheme();
  const color = theme.colors.icon;

  return (
    <svg width="80" height="22" viewBox="0 0 80 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.88889 0.5H16.4444L21.3333 21.8333L14.6667 21.8333L14.2222 19.1667H7.1111L6.66667 21.8333H0L4.88889 0.5ZM9.18518 6.72222H12.1481L13.4074 14.2778H7.92592L9.18518 6.72222Z"
        fill={color}
      />
      <path
        d="M32 0.5C26.109 0.5 21.3333 5.27562 21.3333 11.1666C21.3333 17.0577 26.109 21.8333 32 21.8333H37.3333V14.7222L32.3996 15.395C29.8373 15.7444 27.5556 13.7527 27.5556 11.1667C27.5556 8.58064 29.8373 6.58893 32.3996 6.93833L37.3333 7.61111V0.5H32Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3333 0.5C43.6878 0.5 39.1111 5.07665 39.1111 10.7222V11.6111C39.1111 17.2567 43.6878 21.8333 49.3333 21.8333C54.9789 21.8333 59.5556 17.2567 59.5556 11.6111V10.7222C59.5556 5.07665 54.9789 0.5 49.3333 0.5ZM49.3333 6.72222C47.1242 6.72222 45.3333 8.51308 45.3333 10.7222V11.6111C45.3333 13.8203 47.1242 15.6111 49.3333 15.6111C51.5425 15.6111 53.3333 13.8203 53.3333 11.6111V10.7222C53.3333 8.51308 51.5425 6.72222 49.3333 6.72222Z"
        fill={color}
      />
      <path d="M68 21.8333H61.3333V0.5H67.1111L74.0513 11.9872L73.3333 0.5H80V21.8333H74.2222L67.2821 10.3462L68 21.8333Z" fill={color} />
    </svg>
  );
}
