import styled from 'styled-components';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexColumnAlignCenter = styled(FlexColumn)`
  align-items: center;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexRowAlignBottom = styled(FlexRow)`
  align-items: flex-end;
`;

export const FlexRowAlignCenter = styled(FlexRow)`
  align-items: center;
`;

export const FlexWrap = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const FlexCenterOneItem = styled(FlexRowAlignCenter)`
  justify-content: center;
`;

export const HoverItem = styled.span`
  transition: opacity 0.1s;
  display: inherit;
  align-items: inherit;
  &:hover {
    opacity: 0.4;
  }
`;
