import React, { FC, MouseEventHandler } from 'react';
import Image, { ImageProps } from 'next/legacy/image';

type Props = Omit<ImageProps, 'width'|'height'> & {
  width: string | number;
  height: string | number;
  display?: string;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  onClick?: MouseEventHandler;
  verticalAlign?: string;
  className?: string;
};

const CustomIcon: FC<Props> = (props) => {
  const { width, height, display, marginLeft, marginTop, marginBottom, onClick, verticalAlign, className, ...rest } =
    props;

  return (
    <div
      className={className}
      style={{
        width: width,
        height: height,
        position: 'relative',
        display: display ? display : 'block',
        marginLeft: marginLeft ? `${marginLeft}px` : 0,
        marginTop: marginTop ? `${marginTop}px` : null,
        marginBottom: marginBottom ? `${marginBottom}px` : null,
        verticalAlign: verticalAlign || null,
      }}
      onClick={onClick}
    >
      <Image {...rest} layout={'fill'} unoptimized alt={encodeURIComponent(String(rest.src))}/>
    </div>
  );
};

export { CustomIcon };
