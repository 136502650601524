import React from 'react';
import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { Icon } from '@components/common';
import { GET_CATEGORY_LIST } from '@api';
import category from '@public/categoryIcon.json';
import { convertLangCodeForDB } from '@lib';
import { IconGroupContainer } from './styled';

export const IconGroup: React.FC = ({ ...rest }) => {
  // 번역도구
  const { i18n } = useTranslation('common');
  // 현재 언어
  const { language } = i18n;

  // 데이터 확보
  const { data, loading } = useQuery(GET_CATEGORY_LIST, {
    variables: {
      cateCds: category[language],
      lang: convertLangCodeForDB(i18n.language),
    },
  });

  if (loading) return <div></div>;

  return (
    <IconGroupContainer {...rest}>
      {data?.getTransGodoCategoryItems.map((x: any, i: number) => (
        <Icon className={`category__icon${i + 1}`} key={x.code} name={x.name} code={x.code} />
      ))}
    </IconGroupContainer>
  );
};
