import React, { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import useExchange from '@hooks/useExchange';
import { LanguageCode } from 'src/constants';
import { CustomText, ICustomTextProps } from './ui/Text/CustomText';
import { FlexRowAlignCenter } from './wrapper';

type Props = {
  /** 통화단위와 금액의 공백, tailwind 기준으로, 숫자 1당 4px */
  space?: number;
  /** lineThrough (취소선) 여부 */
  lineThrough?: boolean;
  /** 음수 여부 */
  isNegative?: boolean;
  /** 언어에 따른 금액표시 또는 실제 결제 금액표시 인지에 대한 유형 */
  type: 'display' | 'payment';
  /** 금액  */
  amount: number;
  /** 컴포넌트에 대한 class 명 */
  className?: string;
  /** 금액에서의 포인트 여부 */
  isPoint?: boolean;
  /** 약식기호 여부, ko 에서만 해당 값이 유효함 */
  isAbbreviated?: boolean;
  /** 통화단위를 표시할 지에 대한 여부 */
  isShowCurrencyUnit?: boolean;
  /** 컴포넌트에 대한 스타일 */
  style?: React.CSSProperties;
  /** 통화단위/금액 두개의 CustomText 컴포넌트로 구성되어있는데, 두 개의 공통적으로 넘겨줄 Props */
  commonProps?: ICustomTextProps;
  /** 통화단위/금액 두개의 CustomText 컴포넌트로 구성되어있는데, 두 개의 공통적으로 넘겨줄 Style */
  commonStyle?: React.CSSProperties;
  /** 통화단위 CustomText 컴포넌트의 Props */
  currencyUnitProps?: ICustomTextProps;
  /** 통화단위 CustomText 컴포넌트의 Style */
  currencyUnitStyle?: React.CSSProperties;
  /** 금액 CustomText 컴포넌트의 Props */
  amountProps?: ICustomTextProps;
  /** 금액 CustomText 컴포넌트의 Style */
  amountStyle?: React.CSSProperties;
  /** 해당 컴포넌트 왼쪽에 표시될 텍스트 */
  leftText?: string | ReactNode;
  /** 해당 컴포넌트 오른쪽에 표시될 텍스트 */
  rightText?: string | ReactNode;
};

export default function PriceText({
  className,
  lineThrough,
  isNegative = false,
  space,
  type,
  amount,
  isAbbreviated,
  isPoint = false,
  isShowCurrencyUnit = true,
  style,
  commonProps,
  commonStyle,
  currencyUnitProps,
  currencyUnitStyle,
  amountProps,
  amountStyle,
  leftText,
  rightText,
}: Props) {
  const { i18n } = useTranslation('common');

  const { isNotSupportedCurrencyForPayment, isReady, ex } = useExchange(i18n.language as LanguageCode);
  if (!isReady) return <div></div>;

  const exObj = ex(amount)[type];

  const { unit, position } =
    isPoint && !isNotSupportedCurrencyForPayment
      ? {
        unit: 'P',
        position: 'right',
      }
      : exObj.currencyUnit(isAbbreviated);

  const amountTextProps = { ...commonProps, ...amountProps };
  const currencyUnitTextProps = { ...commonProps, ...currencyUnitProps };

  if (space) {
    if (position === 'left') currencyUnitTextProps.className = `mr-${space}`;

    if (position === 'right') amountTextProps.className = `mr-${space}`;
  }

  if (lineThrough) {
    amountTextProps.className = amountTextProps.className
      ? 'line-through'
      : amountTextProps.className + ' line-through';
    currencyUnitTextProps.className = currencyUnitTextProps.className
      ? 'line-through'
      : currencyUnitTextProps.className + ' line-through';
  }

  const currencyUnitText = (
    <CustomText {...currencyUnitTextProps} style={{ ...commonStyle, ...currencyUnitStyle }}>
      {unit}
    </CustomText>
  );

  const amountText = (
    <CustomText {...amountTextProps} style={{ ...commonStyle, ...amountStyle }}>
      {exObj.amount.toLocaleString('ko-KR')}
    </CustomText>
  );

  return (
    <FlexRowAlignCenter style={style} className={className}>
      {leftText && (
        <CustomText {...commonProps} style={{ ...commonStyle }}>
          {leftText}
        </CustomText>
      )}
      {isNegative && (
        <CustomText {...commonProps} className={'mr-0.5'} style={{ ...commonStyle }}>
          {'-'}
        </CustomText>
      )}
      {isShowCurrencyUnit && position === 'left' && currencyUnitText}
      {amountText}
      {isShowCurrencyUnit && position === 'right' && currencyUnitText}
      {rightText && (
        <CustomText {...commonProps} style={{ ...commonStyle }}>
          {rightText}
        </CustomText>
      )}
    </FlexRowAlignCenter>
  );
}
