import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { TimerText, Wrapper } from './styled';

const Timer = ({ targetDate, displayType, color, isShow }, ref) => {
  // 번역도구
  const { t } = useTranslation('common');

  // 대상 일자
  const targetTime = targetDate && new Date(targetDate).getTime();
  // 일자 간격
  const [diffState, setDiffState] = useState(targetTime - new Date().getTime());

  useEffect(() => {
    // 띠배너에 표시되는 타이머
    const timer = setTimeout(() => {
      setDiffState(targetTime - new Date().getTime());
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  // 날짜 계산
  const calcDate = (() => {
    // 날짜 계산 단위
    const calcSec = 1000;
    const calcMin = calcSec * 60;
    const calcHour = calcMin * 60;
    const calcDay = calcHour * 24;

    // 날짜 비교 계산
    const diffDay = Math.floor(diffState / calcDay).toString();

    let diffAllHour = Math.floor(diffState / calcHour).toString();
    if (diffAllHour.length < 2) diffAllHour = '0' + diffAllHour;

    let diffHour = Math.floor((diffState % calcDay) / calcHour).toString();
    if (diffHour.length < 2) diffHour = '0' + diffHour;

    let diffMin = Math.floor((diffState % calcHour) / calcMin).toString();
    if (diffMin.length < 2) diffMin = '0' + diffMin;

    let diffSec = Math.floor((diffState % calcMin) / calcSec).toString();
    if (diffSec.length < 2) diffSec = '0' + diffSec;

    return (
      <TimerText color={color}>
        {displayType === 'date'
          ? `[${t('bandBanner.timeRemaining')}: ${diffDay}${t('bandBanner.day')}]`
          : displayType === 'time'
            ? `[${t('bandBanner.timeRemaining')}: ${diffAllHour}:${diffMin}:${diffSec}]`
            : `[${t('bandBanner.timeRemaining')}: ${diffDay}${t('bandBanner.day')} ${diffHour}:${diffMin}:${diffSec}]`}
      </TimerText>
    );
  })();

  return (
    <Wrapper isShow={isShow} ref={ref}>
      {calcDate}
    </Wrapper>
  );
};

export default forwardRef(Timer);
