import { getCurrencyUnit } from '@lib/currencyUnit';
import { exchangeRate, exchangeIntoForeignCurrency, estimatedPaymentAmount } from '@lib/price';
import { LanguageCodeEnum, useGetExchangeRateQuery } from 'src/generated/graphql';
import { LANGUAGE_CODE, LanguageCode } from '../constants';

type PriceWithCurrency = {
  amount: number;
  currency: LanguageCode;
  formattedPrice: (space?: boolean) => string;
  currencyUnit: (isAbbreviated?: boolean) => CurrencyUnit;
};

type CurrencyUnit = {
  unit: string;
  position: 'left' | 'right';
};

type ExchangePrice = {
  payment: PriceWithCurrency;
  display: PriceWithCurrency;
};

export default function useExchange(languageCode: LanguageCode) {
  const isNotSupportedCurrencyForPayment = languageCode === LANGUAGE_CODE.CN || languageCode === LANGUAGE_CODE.JP;

  const { data } = useGetExchangeRateQuery({
    variables: {
      // [TODO] : LanguageCode => LanguageCodeEnum
      languageCode: languageCode as string as LanguageCodeEnum,
    },
    skip: !isNotSupportedCurrencyForPayment,
    pollInterval: 12 * 60 * 60 * 1000,
  });

  const isReadyExchange = isNotSupportedCurrencyForPayment ? (data ? true : false) : true;

  const currencyUnit = (languageCode: LanguageCode) => {
    return (isAbbreviated?: boolean) => {
      return getCurrencyUnit(languageCode, isAbbreviated);
    };
  };

  const formattedPrice = (amount: number, languageCode: LanguageCode) => {
    return (space?: boolean) => {
      const formattedPrice = amount.toLocaleString('ko-KR');
      const spaceStr = space ? ' ' : '';

      const { unit, position } = currencyUnit(languageCode)();

      if (position === 'left') return unit + spaceStr + formattedPrice;
      else if (position === 'right') return formattedPrice + spaceStr + unit;
    };
  };

  /**
   * 지정한 언어에 맞는 표시용 금액과 실 결제 금액을 표시해주는 메소드입니다.
   * @param krwPrice 원 금액
   * @returns 표시용 금액과 실 결제 금액을 표시합니다.
   */
  const ex = (krwPrice: number) => {
    if (!isReadyExchange && !isNotSupportedCurrencyForPayment) throw new Error('isReadyExchange가 준비되고 난 이후에 실행해주세요.');

    const krwToUsdExchangeRate = exchangeRate(LANGUAGE_CODE.KO, LANGUAGE_CODE.EN);
    const paymentGateExchangeRate = isNotSupportedCurrencyForPayment ? 1 / data.exchangeRate : 1;

    const usdAmount = exchangeIntoForeignCurrency(krwPrice, krwToUsdExchangeRate);

    if (languageCode === LANGUAGE_CODE.KO) {
      return {
        payment: {
          currency: languageCode,
          amount: krwPrice,
          formattedPrice: formattedPrice(krwPrice, languageCode),
          currencyUnit: currencyUnit(languageCode),
        },
        display: {
          currency: languageCode,
          amount: krwPrice,
          formattedPrice: formattedPrice(krwPrice, languageCode),
          currencyUnit: currencyUnit(languageCode),
        },
      } as ExchangePrice;
      // eslint-disable-next-line brace-style
    }
    // 원화가 아닐 경우에는 모두다 달러로 결제가 됨
    else if (languageCode === LANGUAGE_CODE.EN) {
      return {
        payment: {
          currency: languageCode,
          amount: usdAmount,
          formattedPrice: formattedPrice(usdAmount, languageCode),
          currencyUnit: currencyUnit(languageCode),
        },
        display: {
          currency: languageCode,
          amount: usdAmount,
          formattedPrice: formattedPrice(usdAmount, languageCode),
          currencyUnit: currencyUnit(languageCode),
        },
      } as ExchangePrice;
    }
    const displayAmount = estimatedPaymentAmount(usdAmount, paymentGateExchangeRate);
    return {
      payment: {
        currency: LANGUAGE_CODE.EN,
        amount: usdAmount,
        formattedPrice: formattedPrice(usdAmount, LANGUAGE_CODE.EN),
        currencyUnit: currencyUnit(LANGUAGE_CODE.EN),
      },
      display: {
        currency: languageCode,
        amount: displayAmount,
        formattedPrice: formattedPrice(displayAmount, languageCode),
        currencyUnit: currencyUnit(languageCode),
      },
    } as ExchangePrice;
  };

  return { ex, isReady: isReadyExchange, isNotSupportedCurrencyForPayment };
}
