import { BestProductType } from 'src/generated/graphql';

export const BEST_TAB = {
  DAILY: BestProductType.Day,
  WEEKLY: BestProductType.Week,
  MONTHLY: BestProductType.Month,
  ALLTIME: BestProductType.All,
};

export const BEST_TABS = [
  {
    name: 'tab.best.daily',
    value: BEST_TAB.DAILY,
  },
  {
    name: 'tab.best.weekly',
    value: BEST_TAB.WEEKLY,
  },
  {
    name: 'tab.best.monthly',
    value: BEST_TAB.MONTHLY,
  },
  {
    name: 'tab.best.allTime',
    value: BEST_TAB.ALLTIME,
  },
];

export const BEST_FILTER_CATEGORY = {
  ALL: '251',
  FANTASY: '251004',
  HISTORIC: '251005',
  SF: '251001',
  NOIR: '251002',
  DAILY: '251003',
  FREE: '276001',
};

export const BEST_FILTER_CATEGORY_CODES = [
  BEST_FILTER_CATEGORY.ALL,
  BEST_FILTER_CATEGORY.FANTASY,
  BEST_FILTER_CATEGORY.HISTORIC,
  BEST_FILTER_CATEGORY.SF,
  BEST_FILTER_CATEGORY.NOIR,
  BEST_FILTER_CATEGORY.DAILY,
  BEST_FILTER_CATEGORY.FREE,
];
