import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { getImageSize } from '@lib/index';
import CustomScrollDiv from '@components/ScrollWrapper';

type Props = {
  src: string;
  borderRadius: 'all' | 'top';
};

const Styled = {
  ImageWrapper: styled.div<{ isMobile: boolean }>`
    margin-bottom: -1px;
    background-color: #fff;
    ${(props) =>
    props.isMobile
      ? `
      width: 800px;
      max-height: 800px;
  `
      : `
      width: 420px;
      max-height: 420px;
  `}
  `,
};

export default function ModalImage({ src, borderRadius }: Props) {
  const [imageHeight, setImageHeight] = useState<number>(0);

  const setModalImageHeight = async () => {
    const { width, height } = await getImageSize(`https://storage.acon3d.com/${src}`);

    setImageHeight(height / (width / 420));
  };

  useEffect(() => {
    setModalImageHeight();
  }, [src]);

  const borderRadiusStyle: React.CSSProperties = {
    borderTopLeftRadius: `${isMobile ? 12 : 8}px`,
    borderTopRightRadius: `${isMobile ? 12 : 8}px`,
    ...(borderRadius === 'all' && {
      borderBottomLeftRadius: `${isMobile ? 12 : 8}px`,
      borderBottomRightRadius: `${isMobile ? 12 : 8}px`,
    }),
  };

  return (
    <Styled.ImageWrapper isMobile={isMobile} style={borderRadiusStyle}>
      {imageHeight > 420 && (
        <CustomScrollDiv
          trigger={imageHeight}
          style={{
            ...borderRadiusStyle,
            height: imageHeight > 420 ? 420 : imageHeight,
          }}
        >
          <img className={'w-full'} src={`https://storage.acon3d.com/${src}`} alt="" />
        </CustomScrollDiv>
      )}
      {imageHeight <= 420 && <img style={borderRadiusStyle} className={'w-full'} src={`https://storage.acon3d.com/${src}`} alt="" />}
    </Styled.ImageWrapper>
  );
}
