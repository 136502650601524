import React from 'react';
import styled, { useTheme } from 'styled-components';
import { CustomText } from '@components/ui';

const CountBadge = styled.div<{ isLarge: boolean; badgeColor?: string }>`
  position: absolute;
  background-color: ${(props) => props.badgeColor || props.theme.colors.gnbCount};
  min-width: ${(props) => (props.isLarge ? 26 : 17)}px;
  height: 16px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  left: 15px;
`;

export default function GnbScrap({ className = '', count = null, isLogin = true }) {
  const theme = useTheme();
  const color = theme.colors.icon;

  return (
    <div className={`relative ${className}`}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.84999 3.19999C1.84999 2.70293 2.25294 2.29999 2.74999 2.29999H6.36886C6.78404 2.29999 7.14534 2.58399 7.2434 2.98742L9.41578 11.925H16.7263L18.0767 6.89142H10.6851C10.188 6.89142 9.78507 6.48848 9.78507 5.99142C9.78507 5.49436 10.188 5.09142 10.6851 5.09142H19.25C19.5298 5.09142 19.7938 5.2216 19.9641 5.44365C20.1344 5.66569 20.1918 5.95434 20.1193 6.22463L18.2859 13.0582C18.1804 13.4515 17.8239 13.725 17.4167 13.725H8.70833C8.29315 13.725 7.93185 13.441 7.83379 13.0376L5.66141 4.09999H2.74999C2.25294 4.09999 1.84999 3.69704 1.84999 3.19999Z"
          fill={color}
        />
        <circle cx="9.60105" cy="17.8667" r="1.83333" fill={color} />
        <circle cx="16.0177" cy="17.8667" r="1.83333" fill={color} />
      </svg>
      {count > 0 && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <CountBadge isLarge={count >= 99} badgeColor={!isLogin ? '#4E4EFF' : undefined}>
          <CustomText size={10} weight={500} color={theme.colors.gnbCountText}>
            {count >= 99 ? '99+' : count}
          </CustomText>
        </CountBadge>
      )}
    </div>
  );
}
