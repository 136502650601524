import React from 'react';
import { useTranslation } from 'next-i18next';
import { LANGUAGE_CODE } from 'src/constants';
import { CustomLink, CustomText } from '@components/ui';
import { RightArrowIcon } from 'src/assets/icons';
import { FlexRowAlignCenter } from '@components/wrapper';
import { TitleContent, TitleSkeleton, TitleSkeletonText, TitleSkeletonView, TitleSubText, TitleTextContainer, TitleTop, TitleView, TitleWrapper } from './styled';

interface TitleInfo {
  children?: React.ReactNode;
  isSkeleton?: boolean;
  isView?: boolean;
  viewLinkUrl?: string;
  style?: any;
  titleTextColor?: string;
  subTitle?: string;
  subTitleColor?: string;
  subTitleTextColor?: string;
}

/**
 * 더보기, 조회조건 등 각 페이지에서 사용하는 타이틀
 */
export const Title: React.FC<TitleInfo> = ({
  children,
  viewLinkUrl = '',
  isView = false,
  isSkeleton = false,
  titleTextColor,
  subTitle,
  subTitleColor,
  subTitleTextColor,
  ...style
}) => {
  const { i18n, t } = useTranslation('common');

  return (
    <TitleWrapper {...style}>
      <TitleTop>
        {!isSkeleton ? (
          <TitleContent>
            <TitleTextContainer>
              <CustomText
                letter={i18n.language === LANGUAGE_CODE.KO && -0.02}
                size={24}
                weight={700}
                color={titleTextColor || '#333'}
                lineHeight={'130%'}
              >
                {children}
              </CustomText>
              {subTitle && (
                <TitleSubText color={subTitleColor}>
                  <CustomText color={subTitleTextColor} weight={400} letter={-0.01}>
                    {subTitle}
                  </CustomText>
                </TitleSubText>
              )}
            </TitleTextContainer>
            {isView && (
              <TitleView>
                <CustomLink href={viewLinkUrl}>
                  <FlexRowAlignCenter>
                    <CustomText size={15} lineHeight={'120%'} weight={700}>
                      {t('seeMore')}
                    </CustomText>
                    <RightArrowIcon size={16} />
                  </FlexRowAlignCenter>
                </CustomLink>
              </TitleView>
            )}
          </TitleContent>
        ) : (
          <TitleSkeleton>
            <TitleSkeletonText />
            {isView && <TitleSkeletonView />}
          </TitleSkeleton>
        )}
      </TitleTop>
    </TitleWrapper>
  );
};
