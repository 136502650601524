import React from 'react';
import { useTranslation } from 'next-i18next';
import { SlideBanner } from '@components/common';
import { mainTopSlideCode } from 'src/constants';
import { Wrapper } from './styled';

export const Container: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  // 번역 도구
  const { i18n } = useTranslation('common');

  return (
    <Wrapper>
      <SlideBanner bannerCode={mainTopSlideCode[i18n.language]} isBorder={false} skeletonHeight={400}></SlideBanner>
      {children}
    </Wrapper>
  );
};
