import { captureException as captureExceptionOriginal } from '@sentry/nextjs';
import { ApolloError } from '@apollo/client/errors';
import { ScopeContext } from '@sentry/types/types/scope';

export interface ExtraError extends Partial<ApolloError> {
  extra?: any;
  route?: string;
}
const extractExtra = (extra) => (!extra ? {} : Object.fromEntries(Object.entries(extra).map(([k, v]) => [k, typeof v === 'string' ? v : JSON.stringify(v, null, 2)])));

export function captureException(error: ExtraError, additionalInfo: Partial<ScopeContext> = {}) {
  try {
    const extra = extractExtra(error.extra);
    const { extra: extractedExtra, ...sanitizedAdditionalInfo } = additionalInfo;
    delete error.extra;
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      error.message += '\n[GraphQL Errors] ' + error.graphQLErrors.map(({ message }) => message).join('\n');
    }
    if (error.networkError) {
      error.message += '\n[Network Error] ' + error.networkError;
      // @ts-ignore
      if (error.networkError?.statusCode) extractedExtra.statusCode = error.networkError.statusCode;
      // @ts-ignore
      if (error.networkError?.response?.url) extractedExtra.url = error.networkError.response.url;
    }
    if (error.clientErrors) {
      error.message += '\n[Client Errors] ' + Array.isArray(error.clientErrors) ? error.clientErrors.map(({ message }) => message).join('\n') : error.clientErrors;
    }
    if (error.name) extra.name = error.name;
    if (error.route) extra.route = error.route;
    if (extractedExtra) Object.assign(extra, extractedExtra);
    return captureExceptionOriginal(error, { ...sanitizedAdditionalInfo, extra });
  } catch (e){ /* error in error report*/}
}
