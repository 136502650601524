import styled from 'styled-components';
import { LANGUAGE_CODE } from 'src/constants';
import { CustomText } from '@components/ui';
import { MainImage, SubImage } from './cardimg/styled';

// Card.tsx
export const CardContainer = styled.div<{
  isShowContainer: boolean;
  isDisplayOnScrap?: boolean;
  isScrapEdit?: boolean;
  isChecked?: boolean;
  isDisableThumbnail?: boolean;
  isBorderRound?: boolean;
}>`
  width: 276px;
  min-height: 300px;
  box-sizing: border-box;
  position: relative;
  ${(props) => (props.isScrapEdit ? 'overflow: visible' : 'overflow: hidden')};
  ${(props) => (props.isScrapEdit ? 'cursor: pointer' : 'cursor: auto')};
  ${(props) => (props.isBorderRound && `
    padding: 6px;
    background-color: #fff;
    border-radius: 12px;
  `)}

  ${(props) => {
    if (props.isDisableThumbnail) return '';

    if (props.isScrapEdit && props.isChecked) {
      return `
        ${MainImage} {
          opacity: 0.5;
        }
        ${SubImage} {
          display: none;
        }
      `;
    }

    if (props.isScrapEdit) {
      return `
        &:hover ${MainImage} {
          opacity: 0.5;
        }
        ${SubImage} {
          display: none;
        }
      `;
    }

    return `
      &:hover ${MainImage} {
        opacity: 0;
      }
    `;
  }}

  ${(props) => (props.isShowContainer ? 'height: auto;' : 'height: 180px;')}

  ${(props) =>
    !props.isDisplayOnScrap &&
    `&:nth-last-of-type(-n + 3) {
      margin-bottom: 0px;
    }`}
`;

export const CardHeader = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  overflow: hidden;
  height: 179px;
  border-radius: 6px;
  border: 1px #eee solid;
  ${(props) => (props.isDisabled ? 'pointer-events: none;' : '')};
`;

export const CardContents = styled.div<{ isDisabled?: boolean }>`
  padding: 12px 6px;
  ${(props) => (props.isDisabled ? 'pointer-events: none;' : '')};
`;

export const CardBody = styled.div``;

export const CardMiddle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  & > img {
    cursor: pointer;
  }
`;

export const CardCreater = styled.p`
  font-size: 12px;
  cursor: pointer;
  & > a {
    color: #a0a0a0;
    line-height: 100%;
    display: block;
  }
`;

export const CardTitle = styled.div`
  margin-bottom: 12px;
`;

export const CardTitleText = styled.div<{ lang: string }>`
  width: 100%;
  font-size: 15px;
  letter-spacing: -0.01em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  overflow: hidden;
  word-break: keep-all;
  line-height: 140%;
  font-weight: 400;
  color: #1a1a1a;
  ${(props) =>
    props.lang !== LANGUAGE_CODE.EN &&
    `
    word-break: break-all;
  `}
`;

export const CardPriceContainer = styled.div.attrs({
  className: 'flex items-center',
})`
  margin-bottom: 12px;
  line-height: 100%;
`;

export const CardPriceText = styled.span`
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  color: #aaa;
  text-decoration: line-through;
  line-height: 100%;
  margin-right: 6px;
`;

export const CardRealPrice = styled.div`
  text-align: right;
  line-height: 100%;
`;

export const CardRealPriceUnitText = styled(CustomText)`
  margin-left: 2px;
`;

export const CardPercentText = styled.span.attrs({
  className: 'mr-2',
})`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  line-height: 100%;
  ${(props) => `
    color: ${props['data-active'] ? '#ef00f4' : '#7c7c7c'};
  `}
`;

export const CardRealPriceText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  margin-right: 10px;
`;

// Card -> Footer
export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardExtension = styled.span`
  display: flex;
`;

export const CardExtensionText = styled.span`
  background-color: #f5f5fd;
  border: 1px solid #eaeaf0;
  border-radius: 8px;
  color: #7c7c7c;
  text-align: center;
  font-size: 10px;
  padding: 0px 6px;
  height: 16px;
  font-weight: 400;
  margin-left: 2px;
  box-sizing: border-box;
  line-height: 13px;
  position: relative;
  top: -1px;
`;

export const CardReview = styled.div`
  display: flex;
  & > div {
    vertical-align: top;
  }
`;

export const CardView = styled.div`
  display: flex;
  & > div {
    vertical-align: top;
  }
`;

export const CardReviewCount = styled.span`
  margin: 0px 8px 0px 4px;
  color: #a0a0a0;
  font-size: 12px;
  vertical-align: top;
  line-height: 100%;
`;

export const CardViewCount = styled.span`
  margin: 0px 0px 0px 4px;
  color: #a0a0a0;
  font-size: 12px;
  vertical-align: top;
  line-height: 100%;
`;

export const CardBadgeContainer = styled.div`
  position: absolute;
  left: 8px;
  top: 0px;
  z-index: 5;
  display: flex;
`;

export const CardCount = styled.div`
  display: flex;
`;

export const CardCheckBox = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 12px;
  right: 12px;
  background: ${(props) => (props.isChecked ? '#333333' : '#FFFFFF')};
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  pointer-events: auto;
`;

export const CardBorder = styled.div`
  width: calc(100% + 10px);
  height: 189px;
  top: -5px;
  left: -5px;
  z-index: 4;
  position: absolute;
  border: 2px solid #333333;
  box-sizing: border-box;
  border-radius: 10px;
`;

export const DeleteScrap = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  right: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.75);
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

export const AconOnly = styled.div.attrs({
  className: 'flex',
})`
  margin-bottom: 6px;
`;

export const EndSales = styled.div.attrs({
  className: 'absolute z-10 bottom-0 right-0 w-full',
})`
  padding: 7px 0px 8px 0px;
  text-align: center;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    left: 0px;
    bottom: 0px;
    opacity: 0.6;
    z-index: 1;
  }
`;

export const EndSalesText = styled(CustomText).attrs({
  size: 12,
  color: '#ffffff',
  weight: 500,
})`
  position: relative;
  z-index: 2;
`;

export const BulletPoint = styled(CustomText)`
  margin-right: 2px;
`;
