const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: 'acon',
    locales: ['acon', 'en', 'ko', 'zh', 'ja'],
  },
  ns: ['common', 'member', 'order', 'payment'],
  localePath: typeof window === 'undefined' ? path.resolve('./public/locales') : '/locales',
};
